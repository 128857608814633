import React, { useEffect, useState } from "react";
import image from "../Assets/images/one.jpg";
import { useParams } from "react-router-dom";
import axios from "axios";
import { parseISO, format } from "date-fns";
import Loader from "../components/Loader";
import Footer from "../Layouts/Footer";
import { FadeLoader, GridLoader } from "react-spinners";
import LoaderBlog from "../components/LoaderBlog";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import { Helmet } from "react-helmet";
import Head from "../Layouts/Head";
import CommentForm from "../components/CommentForm";
import Spinner from "../components/Spinner";
import { NotificationManager } from "react-notifications";

import { MdOutlineSubdirectoryArrowLeft } from "react-icons/md";
import { MdClose } from "react-icons/md";
import { Rate } from "rsuite";
import { FaCircleUser } from "react-icons/fa6";
import InputForm from "../components/InputForm";
import BlogInputForm from "../components/BlogInputForm";
import Categories from "../components/Categories";

const BlogView = ({ position }) => {
  const obj = useParams();
  console.log(obj, "blog id");
  const idValue = obj.blog;
  // const Params = useParams();
  // const id = Params.id;
  console.log(idValue, "======");
  //console.log(idValue);
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  const [isDate, setIsDate] = useState();
  const [showTopBtn, setShowTopBtn] = useState(false); // Step 1: State variable to manage the scroll-to-top button
  const [blogData, setBlogData] = useState([]);
  const [rating, setRating] = useState(0);
  const [hoveredRating, setHoveredRating] = useState(0);
  const [commentToggle, setCommentToggle] = useState(false);
  const [commentReply, setCommentReply] = useState(false);
  const [isCommentsData, setIsCommentsData] = useState([]);
  console.log(commentToggle);
  const [commentForm, setCommentForm] = useState({
    name: "",
    email: "",
    comment: "",
    url: "",
  });
  //console.log(data.title, "hello");

  useEffect(() => {
    getApiData();
  }, [obj, idValue]);

  const getApiData = async () => {
    try {
      const res = await axios.get(
        `https://pharmapcdfranchise.co.in/medicine_admin/api/allblogs/${idValue}`
      );
      console.log(res, "guhuh");
      const resAll = await axios.get(
        "https://pharmapcdfranchise.co.in/medicine_admin/api/allblogs"
      );
      // console.log(res.data, "lloooooo");
      const jsonData = resAll.data;

      // Remove the object with matching id from jsonData
      const filteredData = jsonData.filter((item) => item.slug != idValue);

      // console.log("idValue:", idValue);
      //console.log(filteredData, "filtered data");

      setData(res.data);
      console.log(res.data.id, "vnfviof");
      setBlogData(filteredData);
      const dateObject = parseISO(res?.data?.CreatedAt);
      const formattedDate = format(dateObject, "MMM dd, yyyy");
      setIsDate(formattedDate);
      setLoading(false);
    } catch (error) {
      //setLoading(true);
    }
  };

  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCommentForm({ ...commentForm, [name]: value });
  };

  const handleStarHover = (index) => {
    setHoveredRating(index);
  };

  const handleStarClick = (index) => {
    setRating(index);
  };
  const handleMouseLeave = () => {
    setHoveredRating(0);
  };
  const renderStar = (starIndex) => {
    const filled = starIndex <= (hoveredRating || rating);
    const color = filled ? "yellow" : "grey";
    return (
      <span
        key={starIndex}
        style={{ color, cursor: "pointer" }}
        onMouseEnter={() => handleStarHover(starIndex)}
        onClick={() => handleStarClick(starIndex)}
        onMouseLeave={handleMouseLeave}
      >
        ★
      </span>
    );
  };
  const starsCount = 5;
  const stars = Array.from({ length: starsCount }, (_, index) => index + 1);
  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("blog_id", data?.id);
    formData.append("name", commentForm.name);
    formData.append("email", commentForm.email);
    formData.append("website", commentForm.url);
    formData.append("rating", rating);
    formData.append("comment", commentForm.comment);
    try {
      const res = await axios.post(
        `https://pharmapcdfranchise.co.in/medicine_admin/api/add_comment/${data?.id}`,
        formData
      );
      NotificationManager.success("Commnet Add Successful");
      alert("Comment will add please wait for apprpved it");
      console.log(res, "sjkppjj");
      setCommentForm({ name: "", email: "", comment: "", url: "" });
      setRating(0);
    } catch (error) {
      console.log(error, "error");
    }
  };
  const getCommentsData = async () => {
    try {
      const res = await axios.get(
        `https://pharmapcdfranchise.co.in/medicine_admin/api/get_comment/${data?.id}`
      );
      console.log("get_comment", res.data); // I corrected the console.log statement
      setIsCommentsData(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const formatDate = (createdAt) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const date = new Date(createdAt);
    const year = date.getFullYear();
    const month = date.getMonth(); // Month index, 0-indexed
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const hours12 = hours % 12 || 12;

    return `${months[month]} ${day}, ${year} at ${hours12}:${minutes
      .toString()
      .padStart(2, "0")} ${ampm}`;
  };

  // if (loading) {
  //   return <LoaderBlog />;
  // }

  useEffect(() => {
    getCommentsData();
  }, [data]);
  useEffect(() => {
    window.scrollTo(0, position);
  }, [position]);

  return (
    <>
      <Head title={data?.meta_title} description={data?.meta_title} />

      {
        loading? <LoaderBlog/> : (
      <div className="blogparent3">
        <div className="px-md-5 px-2 py-5">
          <div className="row">
            <div className="col-lg-9 col-md-7 col-sm-12">
              <div className="col-12 mx-auto">
                <div className="d-flex justify-content-center ">
                  <LazyLoadImage
                    src={data?.image}
                    className="img-fluid"
                    alt="..."
                  />
                </div>
                <div className="col-12">
                  <div className="d-flex justify-content-between pt-5">
                    {/* <div className="fw-bolder">Categories {data?.category}</div> */}
                    <div className="fw-bolder st-post-date">
                      <span>{isDate}</span>
                    </div>
                  </div>
                  <h1
                    className="header-align fw-bolder py-3"
                    style={{ fontSize: "22px" }}
                  >
                    {data?.title}
                  </h1>
                  <p className="blog_desc_1">
                    {/* Check if data.description is available before rendering */}
                    {data?.description ? (
                      <span
                        dangerouslySetInnerHTML={{ __html: data.description }}
                      />
                    ) : (
                      "No description available"
                    )}
                  </p>
                  {/* <p className="pb-4">
                  {data?.description_front}</p> */}
                </div>
              </div>
              <div className="col-12 my-3">
                <h5 className="comments-title">
                  {isCommentsData.length} Comment{" "}
                </h5>
                {isCommentsData.map((item) => {
                  return (
                    <div
                      className="col-md-6 col-sm-12"
                      id="comments-box"
                      key={item.id}
                    >
                      <div className="comments-box ">
                        <div className=" vcard py-2">
                          {/* <img
                            src="./logo192.png"
                            alt="Dummy Image"
                            className="comments-box-img"
                          /> */}
                          <b className="fn">
                            <p
                              // href="/dummy/"
                              className="url text-white ms-2"
                              //rel="ugc external nofollow"
                            >
                              <FaCircleUser size={30} /> {item.name}
                            </p>
                          </b>{" "}
                          {/* <span className="says text-lowercase">says:</span> */}
                        </div>
                        <div className="comment-metadata p-1">
                          <p className="comment-date">
                            {formatDate(item.created_at)}
                          </p>
                        </div>
                      </div>

                      <div className="comments-content">
                        <p className="my-1">Review</p>
                        <div className="saswp-rvw-str my-1">
                          <Rate size="xs" defaultValue={item.rating} disabled />
                        </div>
                        <p>{item.comment}</p>
                        <button
                          className="comments-box-btn "
                          onClick={() => setCommentToggle(!commentToggle)}
                        >
                          {!commentToggle
                            ? " show all replies"
                            : "hide all replies"}
                        </button>

                        {commentToggle && item.replies.length > 0 && (
                          <>
                            {item.replies.map((reply, repindex) => {
                              <div
                                className="col-sm-12 p-2 mt-2 mb-4 border"
                                key={repindex}
                              >
                                <div className=" vcard p-2">
                                  {/* <img
                                src="./logo192.png"
                                alt="Dummy Image"
                                className="comments-box-img"
                              /> */}
                                  <b className="fn">
                                    <p
                                      className="url"
                                      rel="ugc external nofollow"
                                    >
                                      <FaCircleUser size={30} />
                                      {reply.name}
                                    </p>
                                  </b>{" "}
                                  {/* <span className="says text-lowercase">says:</span> */}
                                </div>
                                <p>{reply.comment}</p>
                                <div className="comment-metadata p-1">
                                  <p>April 26, 2024 at 8:52 AM Youe comments</p>
                                </div>
                              </div>;
                            })}
                          </>
                        )}

                        <br />
                        <button
                          className="replybtn mt-4"
                          onClick={() => setCommentReply(!commentReply)}
                        >
                          {!commentReply ? (
                            <>
                              <MdOutlineSubdirectoryArrowLeft /> Reply
                            </>
                          ) : (
                            <>
                              Cancel <MdClose />
                            </>
                          )}
                        </button>

                        {commentReply && (
                          <CommentForm
                            comment_Id={item.id}
                            blog_Id={item?.blog_id}
                          />
                        )}

                        <br />
                      </div>
                    </div>
                  );
                })}

                {/* <BlogComment /> */}

                <div className="container">
                  <div className="row m-0 card">
                    <div className="col-12 p-4 mx-auto">
                      <div className="row flex-column">
                        <div className="col-lg-3 col-12 ">
                          <h3 className="">Leave a reply</h3>
                          <div>
                            {" "}
                            <h3>
                              {stars.map(renderStar)}
                              {hoveredRating !== 0 && (
                                <span> {hoveredRating}</span>
                              )}
                            </h3>{" "}
                          </div>
                        </div>
                        <div className="col-md-9 col-12">
                          <span id="email-notes">
                            Your email address will not be published.
                          </span>
                          <span className="required-field-message">
                            Required fields are marked{" "}
                            <span className="required">*</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 card-body">
                      <form onSubmit={onSubmit}>
                        <div className="mb-3">
                          <label htmlFor="comment" className="form-label">
                            Comment <span className="required">*</span>
                          </label>
                          <textarea
                            className="form-control"
                            id="comment"
                            value={commentForm.comment}
                            onChange={handleChange}
                            name="comment"
                            rows="8"
                            maxLength="65525"
                            required
                          ></textarea>
                        </div>
                        <div className="mb-3">
                          <label htmlFor="author" className="form-label">
                            Name <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="author"
                            name="name"
                            value={commentForm.name}
                            onChange={handleChange}
                            maxLength="245"
                            autoComplete="name"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="email" className="form-label">
                            Email <span className="required">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            value={commentForm.email}
                            onChange={handleChange}
                            maxLength="100"
                            aria-describedby="email-notes"
                            autoComplete="email"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="url" className="form-label">
                            Website
                          </label>
                          <input
                            type="url"
                            className="form-control"
                            id="url"
                            name="url"
                            value={commentForm.url}
                            onChange={handleChange}
                            maxLength="200"
                            autoComplete="url"
                          />
                        </div>

                        <button
                          type="submit"
                          className="btn btn-primary btn-lg border-0 post-align"
                          disabled={loading} // Disable the button when loading
                        >
                          {loading ? <Spinner /> : "Submit"}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-5 col-sm-12 mt-3 d-none d-md-block form_sticky11">
              {blogData.slice(0, 5).map((item, index) => {
                const dateObject = parseISO(item.CreatedAt);
                const formattedDate = format(dateObject, "MMM dd, yyyy");
                return (
                  <Link
                    to={`/${item.slug}`}
                    className="blog_container"
                    key={index}
                  >
                    <div className="image_container_left">
                      <img src={item.image} width={105} height={105} />
                    </div>

                    <div className="text_container_right">
                      <h6 className="blog_date_top st-post-date">
                        {formattedDate}
                      </h6>
                      <Link to={`/${item.slug}`} style={{ color: "grey" }}>
                        <h5 className="fw-normal one-line-text">
                          {item.title}
                        </h5>
                      </Link>
                      <div
                        className="one-line-text"
                        dangerouslySetInnerHTML={{
                          __html: item?.description_front,
                        }}
                      />
                      {/* <h5 className="blog_desc_55">
                        {item.description_front.slice(0, 70)}...
                      </h5> */}
                    </div>
                  </Link>
                );
              })}

              <div className="">
                <hr />
                <BlogInputForm />
                <hr />
                <div>
                  <Categories/>
                </div>
              </div>
            </div>
            {/* <div className="col-12 d-none d-md-block">
              <div className="d-flex justify-content-between pt-5">
                <div className="fw-bolder">
                  Date <span>{isDate}</span>
                </div>
              </div>
              <h1 className="header-align fw-bolder py-3">{data?.title}</h1>
              <p className="pb-4">{data?.description_front}</p>
            </div> */}
          </div>
        </div>
      </div>
      )
    }
    </>
  );
};

export default BlogView;
