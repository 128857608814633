import React from "react";
import InputForm from "./InputForm";

const SendEnquiry = () => {
  return (
    <>
      <div className="container my-5">
        <div className="row card ">
          <div className="col-12">
            <div className="card-header">
              <h2>Send Enquiry</h2>
            </div>
            <div className="card-body">
              <InputForm />
            </div>
          </div>
        </div>
      </div>

      {/* <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Modal title
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">...</div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div> */}
    </>
  );
};

export default SendEnquiry;
