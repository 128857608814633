import axios from "axios";
import React, { useEffect, useState } from "react";
import { HiOutlineLink } from "react-icons/hi";
import { BeatLoader } from "react-spinners";
import { parseISO, format } from "date-fns";
import { useNavigate, Link, Outlet, useParams } from "react-router-dom";
import LoaderDiv from "../components/LoaderDiv";
import Head from "../Layouts/Head";
import { LazyLoadImage } from "react-lazy-load-image-component";
import IconImageSlider from "../components/IconImageSlider";

const BlogPages = ({ position }) => {
  const navigation = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, position);
  }, [position]);
  // const { id } = useParams();
  // console.log(id, "pageiovdiohdn");
  useEffect(() => {
    setLoading(true);
    apiData();
  }, []);

  const apiData = async () => {
    try {
      const res = await axios.get(
        "https://pharmapcdfranchise.co.in/medicine_admin/api/allblogs"
        // `https://pharmapcdfranchise.co.in/medicine_admin/api/allblogs${id}`
      );
      console.log(res.data);
      setData(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(true);
    }
  };
  const handleNavigation = (id) => {
    navigation(`/blog/${id}`);
  };

  return (
    <>
      <Head title={"Blog Page"} />
      <div className="container ">
        <div className="st-section-heading st-style1">
          <h2 className="st-section-heading-title blog-padding">
            Latest Blogs
          </h2>
          <div
            className="text-center fw-bold para-padding"
            style={{ color: "#499afa", fontWeight: "600" }}
          >
            Sustainability in Pharmaceuticals: A New Era of Responsibility
          </div>
        </div>
        <div className="st-height-b40 st-height-lg-b40" />
      </div>
      <div className="container">
        <div className="row py-5">
          {loading ? (
            <div className="d-flex justify-content-center">
              {/* <BeatLoader color="#007BFF" loading={loading} size={15} /> */}
              <LoaderDiv />
              {/* <span>Loading...</span> */}
            </div>
          ) : (
            data.map((item) => {
              const dateObject = parseISO(item.CreatedAt);
              const formattedDate = format(dateObject, "MMM dd, yyyy");
              return (
                <div className="col-lg-4 col-md-6 mt-4" key={item.id}>
                  <div className="st-post st-style3">
                    <Link
                      to={`/${item.slug}`}
                      className="st-post-thumb st-link-hover-wrap st-zoom"
                    >
                      <LazyLoadImage
                        className="med st-zoom-in"
                        src={item.image}
                        alt={item.image}
                      />
                      <span className="st-link-hover">
                        <i className="fas fa-link">
                          <HiOutlineLink />
                        </i>
                      </span>
                    </Link>
                    <div className="st-post-info">
                      <h5 className="st-post-titlest-post-titlest-post-title text-center mt-2 one-line-text">
                        <Link to={`/${item.slug}`} style={{ color: "grey" }}>
                          {" "}
                          {item.title.charAt(0).toUpperCase() +
                            item.title.slice(1)}
                        </Link>
                      </h5>
                      <div className="st-post-meta d-flex justify-content-between">
                        <span className="st-post-date fw-bolder">
                          {formattedDate}
                        </span>
                        <span>
                          {/* <span className="fw-bolder"> Category: </span>
                            <Link className="st-post-avatar">
                              <span className="st-post-avatar-text ">
                                {" "}
                                {item.category}
                              </span>
                            </Link> */}
                        </span>
                      </div>

                      <div
                        className="st-post-text"
                        dangerouslySetInnerHTML={{
                          __html: item?.description_front.slice(0, 75) + "...",
                        }}
                      />
                    </div>
                    <div className="st-post-footer ">
                      <Link
                        to={`/${item.slug}`}
                        className="btn-refremerj"
                        style={{ color: "grey" }}
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                  <div className="st-height-b0 st-height-lg-b30" />
                </div>
              );
            })
          )}
        </div>
      </div>
      <IconImageSlider />
    </>
  );
};

export default BlogPages;
