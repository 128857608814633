import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {
  BrowserRouter,
  createBrowserRouter,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import ErrorPage from "./pages/ErrorPage";
import Root from "./Router/Root";
import BlogView from "./pages/BlogView";
import Loader from "./components/Loader";
import store from "./Redux/store";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import BlogPages from "./pages/BlogPages";
import RowData from "./pages/RowData";
import DivisonsPage from "./pages/DivisonsPage";
import CompanyDetails from "./pages/CompanyDetails";
import CategoriesPage from "./pages/CategoriesPage";
import DivisonDetails from "./pages/DivisonDetails";
import { HelmetProvider } from "react-helmet-async";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import Head from "./Layouts/Head";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SendEnquiry from "./components/SendEnquiry";
const helmetContext = { Head };

function Main() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Replace 2000ms with the actual loading time of your app
  }, []);
  const customStyles = {
    vertical: "top", // Position the notifications at the top
    horizontal: "center", // Center the notifications horizontally
  };
  window.dataLayer.push({
    event: "pageview",
  });

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-MSKDXMNK",
      dataLayer: {
        page:
          document.location.origin +
          document.location.pathname +
          document.location.search,
      },
    };
    TagManager.initialize(tagManagerArgs);
    ReactGA.initialize("G-2ZMLX8BJHQ");
    // Track initial pageview
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <App />,
        },
        {
          path: "/blog/",
          element: <BlogPages />,
        },
        {
          path: ":blog",
          element: <BlogView />,
        },
        {
          path: "/rowData",
          element: <RowData />,
        },
        {
          path: "/:cat/*",
          element: <CategoriesPage />,
        },
        {
          path: "/divisions-details/:id",
          element: <DivisonDetails />,
        },
        {
          path: "/policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "/enquiryform",
          element: <SendEnquiry />,
        },
      ],
    },
  ]);

  return (
    <React.StrictMode>
      <HelmetProvider context={helmetContext}>
        {loading ? (
          <Loader />
        ) : (
          <SnackbarProvider maxSnack={3} anchorOrigin={customStyles}>
            <Provider store={store}>
              <RouterProvider router={router} />
              <Outlet />
            </Provider>
            <NotificationContainer/>
          </SnackbarProvider>
        )}
      </HelmetProvider>
    </React.StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Main />);
// if (root.hasChildNodes()) {
//   console.log('App: Hydrate');
//   hydrate(<App />, root);
// } else {
//   console.log('App: Render');
//   render(<App />, root);
// }
