import React, { useEffect, useState } from "react";
import { setCat } from "../Redux/Action";
import { useDispatch } from "react-redux";
// import { Link, useNavigate } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { FaHandPointRight, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import axios from "axios";

function Categories() {
  const dispatch = useDispatch();
  const [isCategoryVisible, setIsCategoryVisible] = useState(false);
  //console.log(isCategoryVisible, 'mobile screen');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  console.log("cAT", data);
  // console.log(data,'======data');
  useEffect(() => {
    setLoading(true);
    getApiData();

    // Check the initial screen width and set visibility accordingly
  }, []);

  const getApiData = async () => {
    try {
      const res = await axios.get(
        "https://pharmapcdfranchise.co.in/medicine_admin/api/categorybyidss"
      );
      const shuffledData = [...res.data];
      //console.log(shuffledData, res.data, "siffgiafnkafbaknskdfnkD");
      for (let i = shuffledData.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledData[i], shuffledData[j]] = [shuffledData[j], shuffledData[i]];
      }
      setData(shuffledData);
      //setData(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(true);
    }
  };
  useEffect(() => {}, []);

  const toggleCategoryVisibility = () => {
    setIsCategoryVisible(!isCategoryVisible);
    // console.log(isCategoryVisible, "jjjj")
  };

  const handleClick = (item) => {
    dispatch(setCat(item));
    //console.log(item);
    // setActive(item);
    // setCategoryAll(false);
  };

  return (
    <section>
      <ul>
        {data.map((item, index) => {
          return (
            <Link
              key={index}
              to={`/${item?.category_slug.toLowerCase()}/*`}
              onClick={toggleCategoryVisibility}
            >
              <li
                className={`text-dark fw-bold `}
                onClick={() => handleClick(item.category)}
              >
                {/* <span className="pe-2">
                  <FaHandPointRight />{" "}
                </span> */}
                {item.category}
              </li>
            </Link>
          );
        })}
      </ul>
    </section>
  );
}

export default Categories;
